import React from 'react';
import Navbar from "./nav/Navbar";
import './LayoutContainer.css';
import {Container, Row} from "react-bootstrap";

const LayoutContainer = (props) => {
  return (
    <Container>
      <Row>
        <Navbar/>
      </Row>
      <Row className="mainContent">
        {props.children}
      </Row>
    </Container>
  );
};

export default LayoutContainer;
