import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './normalize.css';
import BlogHome from "./home/BlogHome";
import PostPage from "./post/PostPage";
import Me from "./about/Me";
import { Container } from 'react-bootstrap';
import NewPost from './home/New';
import Home from './plaintext/Home';

function App() {

  const hexColors = [
    "#a0a896",
    "#949d89",
    "#fffbbd",
    "#99aa8b",
    "#9cbfc6",
    "#99aa8b",
    "#c0d6e4",
    "#7c989e",
    "#9cbfc6",
    "#91a9b4",
    "#9966cc",
    "#ccccff",
    "#8cbed6",
    "#9966cc",
    "#fdc700",
    "#30f7be",
    "#7787b1",
    "#db9c9f",
    "#99aadd",
    "#ff6800",
    "#c9a287",
    "#ff6900",
    "#bf9171",
    "#00f8ff",
    "#acf0f2",
    "#00f9ff",
    "#67dde0",
    "#00ff49",
    "#a4ffbe",
    "#75ea9c",
    "#ffaf00",
    "#ffe4a9",
  ]

  const randomColor = hexColors[Math.floor(Math.random() * hexColors.length)];

  return (
    <Container fluid className="App" style={{backgroundColor: randomColor}}>
      <Switch>
        <Route exact path="/blog/">
          <Redirect to="/"/>
        </Route>
        <Route exact path="/" component={BlogHome}/>
        <Route exact path="/blog/type/:category" component={BlogHome}/>
        <Route exact path="/blog/post/:id" component={PostPage}/>
        <Route exact path="/blog/me" component={Me}/>
        <Route exact path="/blog/new" component={NewPost}/>
        <Route exact path="/pt/" component={Home}/>
      </Switch>
    </Container>
  );
}

export default App;
