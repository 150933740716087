import React, {useEffect, useState} from 'react';
import './PostTable.css';
import {Col, Container, Row} from "react-bootstrap";
import PostCard from "../../base/PostCard";
import CloseButton from 'react-bootstrap/CloseButton';
import Form from 'react-bootstrap/Form';

export const hexColors = [
    "#a0a896",
    "#949d89",
    "#fffbbd",
    "#99aa8b",
    "#9cbfc6",
    "#99aa8b",
    "#c0d6e4",
    "#7c989e",
    "#9cbfc6",
    "#91a9b4",
    "#9966cc",
    "#ccccff",
    "#8cbed6",
    "#9966cc",
    "#fdc700",
    "#30f7be",
    "#7787b1",
    "#db9c9f",
    "#99aadd",
    "#ff6800",
    "#c9a287",
    "#ff6900",
    "#bf9171",
    "#00f8ff",
    "#acf0f2",
    "#00f9ff",
    "#67dde0",
    "#00ff49",
    "#a4ffbe",
    "#75ea9c",
    "#ffaf00",
    "#ffe4a9",
]

const PostTable2 = (props) => {

    return (
        <Container>
            <Row>
                {
                    props.posts.map((post, index) => {
                        const randomColor = hexColors[Math.floor(Math.random() * hexColors.length)];
                        return <Col key={index} md={3} className={"p-4"}>
                            <PostCard
                                pid={post.pid}
                                bgColor={randomColor}
                                cardHeader={`#${post.pid} on ${post.pubDate}`}
                                cardText={post.title}
                                cardFooter={post.tags.replaceAll(",", " /").toLowerCase()}
                            />
                        </Col>
                    })
                }
            </Row>
        </Container>
    );
};

export default PostTable2;
