import React from 'react';
import LayoutContainer from "../base/LayoutContainer";
import './Me.css';
import { Link } from "react-router-dom";


const Me = () => {

  return (
    <LayoutContainer>
      <div className="container">
        <div className="row">
          <div className="twelve columns middleCenter">
            <h1>
              So... who Am I?
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns whoamI">
            <p>
              Nobody.
            </p>
            <p>
              I may have found myself as a low profile person with mild humor, mostly self degrading, but I am still searching. But I have evolved.
              I am not the same person as I was 10 years ago in college. I am also not the same person I was four years ago.
              I am proud to say I am the most evolved me. And I am only one of my type.
            </p>
            <p>
              I work hard. Nothing comes easy to me. That defines what I have been, now, and will do. Sometimes when I set
              my mind to something, I don't stop. Like this website that I setup in two days.
            </p>
            <p>
              I search for happiness and I know it isn't there. But I choose to ignore.
            </p>
            <p>
              Name? Rohit. Friends call me Kulki. Close friends call me Kulfi.
            </p>
            <br />
            <p>
              Happy Trails!
            </p>
            <p>
              <img src={"https://i.imgur.com/GL0Dd9Q.jpg"} width={"80%"} alt={"me"} />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="p-5 m-5 twelve columns middleCenter">
            <Link to="/">
              <button className="btn btn-primary whoamI" type="submit">
                Back Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Me;
