import React, {useEffect, useState} from 'react';
import LayoutContainer from "../base/LayoutContainer";
import axios from 'axios';
import './PostPage.css';
import {Remarkable} from 'remarkable';
import {Link} from "react-router-dom";
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {Helmet} from 'react-helmet';
import DOMPurify from 'dompurify';

const initialPost = {
    title: "",
    content: "",
    pubDate: "",
    tags: "",
    pid: ""
}

const md = new Remarkable({
    html: true
});

const PostPage = () => {

    const [post, setPost] = useState(initialPost);

    const postId = window.location.href.split("/")[5];

    useEffect(() => {
        if (postId !== undefined && postId !== "" && post.title === "") {
            axios
                .get(`/base/api/p?p=${postId}`)
                .then(response => {
                    console.log(response);
                    setPost(response.data);
                    document.title = post.title ?? "...";
                })
                .catch(error => console.error(`There was an error retrieving the post: ${error}`))
        }
    }, [])

    if (post && post.title === "") {
        return <LayoutContainer>
            <Container className={'middleCenter'}>
                <Spinner animation={'grow'} size={'lg'}/>
            </Container>
        </LayoutContainer>
    }

    return (
        <LayoutContainer>
            <Container style={{width: 'max(50vw, 500px)'}}>
                <Helmet titleTemplate="Kulfi's thoughts - %s">
                    <meta charSet="utf-8"/>
                    <title>{post.title ?? "..."}</title>
                    <meta id="og-title" property="og:title" content={post.title ?? "..."}/>
                </Helmet>
                <Row>
                    <div className="twelve columns middleCenter postMainTitle">
                        {post.title}
                    </div>
                </Row>
                <Row>
                    <Col style={{fontSize: 'min(3vw, 1em)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        Rohit's blog
                    </Col>
                    <Col style={{
                        fontSize: 'min(3vw, 1em)',
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {post.tags.replaceAll(",", " /").toLowerCase()}
                    </Col>
                    <Col style={{fontSize: 'min(3vw, 1em)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        {post.pubDate}
                    </Col>
                </Row>
                <Row>
                    <div className="postMainContent pt-4">
                        <div className={'pt-4'} dangerouslySetInnerHTML={{__html: md.render(DOMPurify.sanitize(post.content))}} />
                    </div>
                </Row>
                <Row className='pt-5 pb-5 mt-5 mb-5'>
                    <Link to="/">
                        <Button style={{fontSize: 'min(3vw, 1em)'}} variant="primary" type="submit">
                            Back Home
                        </Button>
                    </Link>
                </Row>
            </Container>
        </LayoutContainer>
    )
        ;
};

export default PostPage;
