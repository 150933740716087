import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import './ShadowCard.css';
import {Link} from "react-router-dom";

class PostCard extends Component {
    render() {
        return (
            <Card className="homecard" style={{backgroundColor: this.props.bgColor || "#ffffff"}}>
                <Card.Header className={"card-date"} as="span" style={{backgroundColor: this.props.bgColor || "#ffffff"}}>
                    {this.props.cardHeader}
                </Card.Header>
                <Link style={{textDecoration: "none", color: "black"}}
                      to={`/blog/post/${this.props.pid}`}>
                    <Card.Body className="d-flex flex-column" style={{backgroundColor: this.props.bgColor || "#ffffff", opacity: "0.4"}}>
                        <Card.Text className={"card-text"}
                                   style={{backgroundColor: this.props.bgColor || "#ffffff", backdropFilter: "blur(10px)"}}>
                            {this.props.cardText}
                        </Card.Text>
                        <Card.Footer className={"card-foot"} style={{backgroundColor: this.props.bgColor || "#ffffff"}}>
                            {this.props.cardFooter}
                        </Card.Footer>
                    </Card.Body>
                </Link>
            </Card>
        );
    }
}

export default PostCard;
