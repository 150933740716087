import React, {useEffect, useState} from 'react';
import {hexColors} from '../home/list/PostTable2';
import {Link} from 'react-router-dom';
import Layout from './Layout';
import {Badge, Button, Col, Container, Row} from "react-bootstrap";
import axios from 'axios';
import {Helmet} from 'react-helmet';

const Home = () => {

    const initialPosts = [];

    const randomColor = hexColors[Math.floor(Math.random() * hexColors.length)];

    const [categories, setCategories] = useState([]);

    const category = window.location.href.split("/")[5] || null;

    const [posts, setPosts] = useState(initialPosts);

    const [filteredPosts, setFilteredPosts] = useState(posts);

    useEffect(() => {
        if (posts.length > 0 && category !== "" && category !== null) {
            setFilteredPosts(posts.filter(p => p.tags.includes(category)));
        } else {
            setFilteredPosts(posts);
        }
    }, [posts, category]);

    useEffect(() => {
        if (posts) {
            axios
                .get(`/base/api/ps`)
                .then(response => {
                    setPosts(response.data.sort((a, b) => b.pid - a.pid));
                    const individualCategories = [];
                    response.data.forEach(c => {
                        const postCategoryList = c.tags.split(',').map(t => t.toLowerCase().trim(' '));
                        postCategoryList.forEach(cp => {
                            individualCategories.push(cp);
                        })
                    })
                    individualCategories.sort();
                    setCategories(individualCategories.reduce(function (map, word) {
                        map[word] = (map[word] || 0) + 1;
                        return map;
                    }, Object.create(null)))
                })
                .catch(error => console.error(`There was an error retrieving the list: ${error}`))
        }
    }, []);

    const uniqC = [];

    const categoryBox = <Row>
        {
            Object.keys(categories).map(t => {
                uniqC.push(t);
                return <Link to={'/blog/type/' + t}><Badge bg="dark" style={{fontFamily: 'Major Mono Display', marginBottom: '2px'}} key={t}
                                                           to={'/blog/type/' + t}>{categories[t]}/{t}</Badge></Link>
            })
        }
    </Row>

    console.log([...new Set(uniqC)]);

    return (
        <Layout>
            <Helmet titleTemplate="Kulfi's thoughts - %s">
                <meta charSet="utf-8"/>
                <title>Everything..</title>
                <meta id="og-title" property="og:title" content='Everything..'/>
            </Helmet>

            <Container>
                <Row>
                    <Col>
                        <Row>
                            <Col style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontFamily: 'Major Mono Display',
                                fontSize: 'min(6vw, 3em)'
                            }}>
                                Rohit's Miniblog
                            </Col>
                        </Row>
                        <Row style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Col md={{ span: 2 }} xs={2}>
                                <Link to="/blog/me">
                                    <Button style={{fontSize: 'min(3vw, 1em)'}}
                                            variant='dark'>
                                        Who
                                    </Button>
                                </Link>
                            </Col>
                            <Col md={{ span: 2 }} xs={2}>
                                <Link to="/blog/new">
                                    <Button style={{fontSize: 'min(3vw, 1em)'}}
                                            variant='light'>
                                        New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} style={{display: 'inline', fontStyle: 'min(2vw, 12px)'}}>
                        {categoryBox}
                    </Col>
                    <Col md={10}>
                        {/*<PostTable2 posts={filteredPosts} category={category}/>*/}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Home;
