import React from 'react';
import {Container, Row} from "react-bootstrap";
import Nav from './Nav';

const Layout = (props) => {
    return (
        <Container>
            <Row>
                <Nav/>
            </Row>
            <Row style={{paddingTop: '1vh'}}>
                {props.children}
            </Row>
        </Container>
    );
};

export default Layout;
